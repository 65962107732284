import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import PressRelease from 'components/PressRelease';

const PressReleasePage: React.FC = () => (
  <Layout>
    <SEO title="プレスリリース" />
    <PressRelease />
  </Layout>
);

export default PressReleasePage;
